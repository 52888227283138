<template>
  <div class="menu__item">
    <draggable
      :group="{ name: 'g-' + new Date().getUTCMilliseconds() }"
      class="dragArea"
      tag="ul"
      :list="items"
      :options="{ animation: 150 }"
    >
      <li v-for="(el, index) in items" :key="index">
        <v-row no-gutters class="d-flex align-center py-2">
          <v-col cols="6" class="d-flex align-center">
            <div class="mr-3">
              <v-icon>fas fa-grip-vertical</v-icon>
            </div>
            <!-- <pre>{{ el }}</pre> -->
            <div>{{ el.name }}</div>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-end">
            <v-btn @click="onShowUploadImage(el, index)" v-if="el.root" class="mr-2">Upload banner</v-btn>
            <v-btn @click="onShowEdit(el)" class="mr-2">Edit</v-btn>
            <v-btn @click="onShowCreate(el)" class="mr-2">Add new item</v-btn>
            <v-btn @click="onDeleteNote(index)"><v-icon>far fa-trash-alt</v-icon></v-btn>
          </v-col>
        </v-row>
        <nested-draggable :items="el.children" />
      </li>
    </draggable>
    <!-- Modal create/edit item -->
    <modal title="Menu item" :isLoading="turnOffBtn" model="showModal" :callback="saveItem">
      <template slot="content">
        <div>
          <span class=""> Name </span>
          <v-text-field
            v-model="item.name"
            :error-messages="invalid && !$v.item.name.required ? $t('Name is required') : ''"
            placeholder="e.g. About us"
          ></v-text-field>
        </div>
        <div>
          <span class=""> Link </span>
          <div class="my-3">
            <v-chip
              class="mr-2 mt-2"
              @click:close="
                removeLink();
                linkObject = null;
                showSelectCateLink = true;
              "
              close
              v-if="linkObject && linkObject.name"
            >
              {{ linkObject.name }}
            </v-chip>
            <v-chip
              class="mr-2 mt-2"
              @click:close="
                removeSubLink();
                selectedLink = null;
                subLink = null;
              "
              close
              v-if="selectedLink"
            >
              {{ selectedLink.title }}
            </v-chip>
          </div>
          <v-combobox
            v-if="showSelectCateLink"
            placeholder="Search or parte a link"
            v-model="linkObject"
            @change="loadDataLink"
            :items="linkItems"
            item-text="name"
            item-value="type"
            hide-details
          ></v-combobox>
          <v-autocomplete
            v-if="!selectedLink && linkObject && linkObject.type && linkObject.type !== 'home'"
            :search-input.sync="searchLink"
            v-model="subLink"
            @change="chooseSubLink"
            :placeholder="'Search ' + linkObject.name"
            :items="dataLinks"
            :loading="isLoadingDataLink"
            item-text="title"
            hide-details
          ></v-autocomplete>
          <!-- <div v-if="formError" class="mt-3 v-messages theme--light error--text">
            {{ formError }}
          </div> -->
        </div>
      </template>
    </modal>
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          Upload banner
        </v-card-title>
        <v-card-text v-if="elActive">
          <UploadOneImage :image="{ src: elActive.banner.src }" @callback="getCallbackImage" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="saveBanner">
            Save
          </v-btn> -->
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { required, minLength, maxLength, email, helpers, decimal } from 'vuelidate/lib/validators';
import modal from '@/components/Modal';
import slugify from '@/helpers/slugify';
import { collectionApi } from '@/apis/collection';
import { productApi } from '@/apis/product';
import { pageService } from '@/apis/page.s';

export default {
  name: 'nested-draggable',
  components: {
    draggable,
    modal,
  },
  props: {
    // menu items
    items: {
      type: Array,
      default: null,
    },
    callback: {
      type: Function,
      default(element) {},
    },
  },
  data() {
    return {
      turnOffBtn: false,
      item: {
        name: '',
        link: '',
        handle: '',
        children: [],
      },
      formError: null,
      note: null,
      invalid: false,
      showModal: false,
      isCreated: false,
      showSelectCateLink: true,
      isLoadingDataLink: false,
      showSubLink: false,
      subLink: '',
      selectedLink: null,
      linkObject: null,
      searchLink: null,
      dataLinks: [],
      linkItems: [
        {
          name: 'Home page',
          type: 'home',
          link: '/',
        },
        {
          name: 'Collections',
          type: 'collections',
          link: '/collections/',
        },
        {
          name: 'Products',
          type: 'products',
          link: '/products/',
        },
        {
          name: 'Pages',
          type: 'pages',
          link: '/pages/',
        },
        {
          name: 'Policies',
          type: 'policies',
          link: '/policies/',
        },
      ],
      policies: [
        {
          title: 'Refund policy',
          handle: 'refund-policy',
        },
        {
          title: 'Privacy policy',
          handle: 'privacy-policy',
        },
        {
          title: 'Terms of services',
          handle: 'terms-of-services',
        },
        {
          title: 'Shipping policy',
          handle: 'shipping-policy',
        },
      ],
      dialog: false,
      elActive: null,
      index: null,
    };
  },
  created() {
    // this.items.map(menu => {
    //   let obj = Object.assign(menu, { deep: 1 });
    //   this.itemsIndex.push(obj);
    // });
    // console.log('this.itemsIndex', this.itemsIndex);
  },
  validations: {
    item: {
      name: {
        required,
      },
      link: {
        required: false,
      },
    },
  },
  methods: {
    /*
      Show modal create item
      input: object
      output: void
    */
    onShowCreate(data) {
      this.note = data;
      this.turnOffBtn = false;
      this.showModal = true;
      this.invalid = false;
      this.item.name = '';
      this.item.link = '';
      this.selectedLink = null;
      this.linkObject = null;
      this.subLink = null;
      this.showSelectCateLink = true;
      this.searchLink = '';
      this.isCreated = true;
    },
    async removeLink() {
      this.item.link = '';
      this.selectedLink = null;
      this.subLink = null;
    },
    async removeSubLink() {
      this.item.link = await this.item.link.replace(this.selectedLink.handle, '');
      this.searchLink = '';
      this.selectedLink = null;
      this.subLink = null;
    },
    async chooseSubLink() {
      this.selectedLink = this.dataLinks.find(link => link.title === this.subLink);
      let link = '';
      if (this.linkObject.type === 'collections') {
        link = '/collections/' + this.selectedLink.handle;
      } else {
        link = this.linkObject.link + this.selectedLink.handle;
      }
      this.$set(this.item, 'link', link);
      this.$forceUpdate();
      this.showSelectCateLink = false;
      this.formError = null;
    },
    async loadDataLink() {
      this.isLoadingDataLink = true;
      let params = {
        search: this.searchLink,
      };
      this.showSubLink = this.linkObject.type && this.linkObject.type !== 'home';
      if (this.linkObject && this.linkObject.type === 'collections') {
        this.item.link = this.linkObject.link;
        this.itemTextLink = 'title';
        let documentData = await collectionApi.get(params);
        this.dataLinks = documentData.data.collections;
      } else if (this.linkObject && this.linkObject.type === 'products') {
        this.item.link = this.linkObject.link;
        this.itemTextLink = '';
        let documentData = await productApi.get(params);
        this.dataLinks = documentData.data.products;
      } else if (this.linkObject && this.linkObject.type === 'pages') {
        this.item.link = this.linkObject.link;
        this.itemTextLink = '';
        let documentData = await pageService.list(params);
        this.dataLinks = documentData.data.pages;
      } else if (this.linkObject && this.linkObject.type === 'policies') {
        this.item.link = this.linkObject.link;
        this.dataLinks = this.policies;
      } else if (this.linkObject && this.linkObject.type === 'home') {
        this.item.link = '/';
      } else {
        this.item.link = this.linkObject;
      }
      this.isLoadingDataLink = false;
      this.formError = null;
    },
    /*
      Show modal edit item
      input: object
      output: void
    */
    async onShowEdit(data) {
      this.note = data;
      this.turnOffBtn = false;
      this.showModal = true;
      this.invalid = false;
      this.item.name = data.name;
      this.item.link = data.link;
      this.isCreated = false;
      this.showSelectCateLink = false;
      let items = data.link ? data.link.split('/') : [];
      if (items.includes('collections') || items.includes('c')) {
        this.linkObject = {
          name: 'Collections',
          type: 'collections',
          link: '/collections/',
        };
        try {
          let handle = items.length && items.length > 1 ? items[2] : '';
          let params = {
            search: handle ? handle.replaceAll('-', ' ') : '',
          };
          // let documentData = await collectionApi.get(params);
          if (handle && handle !== '') {
            let linkByHandle = await collectionApi.getLinkByHandle(handle);
            if (linkByHandle) {
              this.selectedLink = linkByHandle.data.collection;
            }
          }
          // if (documentData) {
          //   this.selectedLink = documentData.data.collections.find(c => c.handle === handle);
          // }
        } catch (error) {
          console.log(error);
        }
      } else if (items.includes('p') || items.includes('products')) {
        this.linkObject = {
          name: 'Products',
          type: 'products',
          link: '/products/',
        };
        try {
          let handle = items.length && items.length > 1 ? items[2] : '';
          let params = {
            search: handle ? handle.replaceAll('-', ' ') : '',
          };
          if (handle && handle !== '') {
            let documentData = await productApi.getByHandle(handle);
            if (documentData) {
              this.selectedLink = documentData.data.product;
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else if (items.includes('pages')) {
        this.linkObject = {
          name: 'Pages',
          type: 'pages',
          link: '/pages/',
        };
        try {
          let handle = items.length && items.length > 1 ? items[2] : '';
          let params = {
            search: handle ? handle.replaceAll('-', ' ') : '',
          };
          let documentData = await pageService.list();
          if (documentData) {
            this.selectedLink = documentData.data.pages.find(c => c.handle === handle);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (items.includes('policies')) {
        this.linkObject = {
          name: 'Policies',
          type: 'policies',
          link: '/policies/',
        };
        try {
          let handle = items.length && items.length > 1 ? items[2] : '';
          this.selectedLink = this.policies.find(c => c.handle === handle);
        } catch (error) {
          console.log(error);
        }
      } else if (data.link === '/') {
        this.linkObject = {
          name: 'Home page',
          type: 'home',
          link: '/',
        };
        this.selectedLink = null;
      } else {
        this.linkObject = data.link;
        this.selectedLink = null;
        this.showSelectCateLink = true;
      }
    },
    /*
      Delete item
      input: object
      output: void
    */
    onDeleteNote(index) {
      this.items.splice(index, 1);
    },
    /*
      Func save modal
      input: void
      output: void
    */
    saveItem() {
      this.$v.$touch();
      this.invalid = this.$v.item.$invalid;
      setTimeout(() => {
        if (!this.invalid) {
          let obj = {};
          obj.id = 'item-' + new Date().getTime().toString(36);
          obj.name = this.item.name;
          obj.link = this.item.link;
          obj.handle = slugify(this.item.name);
          obj.children = [];
          if (this.note && !this.note.children) {
            this.note.children = [];
          }
          if (this.isCreated) {
            this.note.children.push(obj);
            this.showModal = false;
            this.$forceUpdate();
          } else {
            this.note.name = this.item.name;
            this.note.handle = slugify(this.item.name);
            this.note.link = this.item.link;
            this.showModal = false;
          }
        }
      }, 500);
    },
    onShowUploadImage(el, index) {
      this.index = index;
      console.log('el', el);
      if (el && el.banner && el.banner.src) {
        this.elActive = el;
      } else {
        this.elActive = Object.assign({}, el, { banner: { src: '' } });
      }
      this.$forceUpdate();
      this.dialog = true;
    },
    getCallbackImage(img) {
      this.items[this.index].banner = img;
      this.elActive.banner = img;
      this.$forceUpdate();
    },
  },
  watch: {
    searchLink() {
      this.loadDataLink();
    },
  },
};
</script>
<style lang="scss">
.v-chip.v-size--default {
  height: auto;
  padding: 5px 10px;
}
.v-chip .v-chip__content {
  white-space: pre-wrap;
}
.menu__item {
  ul {
    list-style: none;
  }
  li {
    cursor: move;
  }
}
</style>
